import(/* webpackMode: "eager" */ "/Users/a61811/Documents/insure24/insure24-ui/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/Users/a61811/Documents/insure24/insure24-ui/public/logo.png");
;
import(/* webpackMode: "eager" */ "/Users/a61811/Documents/insure24/insure24-ui/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/a61811/Documents/insure24/insure24-ui/src/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/a61811/Documents/insure24/insure24-ui/src/lib/registry.tsx");
;
import(/* webpackMode: "eager" */ "/Users/a61811/Documents/insure24/insure24-ui/src/styles/main.scss");
